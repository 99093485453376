import React, { Component } from "react";
import { Link } from "react-router-dom";

import Image from "../utils/Image";

import "./Offers.css";
import emptyImage from "./flips_empty_2025.png";

// const gastro = [
//   { i: 37, p: 'p61.png?v=2'},
//   { i: 91, p: 'p63.png?v=2'},
//   { i: 86, p: 'p111.png?v=2'},
//   { i: 49, p: 'p176.png?v=2'},
//   { i: 95, p: 'p181.png?v=2'}
// ]

const PATH = "/angebote";
const IMAGE_YEAR = "preview2023_ads";

const freizeit = [
  { i: 3 - 1, pp: 67 },
  { i: 36 - 1, pp: 50 },
  { i: 19 - 1, pp: 33 },

  { i: 15 - 1, pp: 17 },
  { i: 24 - 1, pp: 71 },
];

const gastro1 = [
  { i: 75 - 1, pp: 123 },
  { i: 96 - 1, pp: 87 },
  { i: 118 - 1, pp: 93 },

  { i: 103 - 1, pp: 89 },
  { i: 91 - 1, pp: 145 },
  // { i: 78 - 1, pp: 88 },
  { i: 135 - 1, pp: 91 },
];

const gastro2 = [
  { i: 106 - 1, pp: 85 },
  { i: 133 - 1, pp: 105 },
  { i: 149 - 1, pp: 97 },
  { i: 112 - 1, pp: 142 },
  { i: 138 - 1, pp: 114 },
];

// const handel = [
//   { i: 161 - 1, pp: 188 },
//   { i: 170 - 1, pp: 193 },
//   { i: 162 - 1, pp: 175 },
//   { i: 165 - 1, pp: 173 },
//   { i: 172 - 1, pp: 181 },
// ];

const region = [
  { i: 173 - 1, pp: 211 },
  { i: 169 - 1, pp: 204 },
  { i: 201 - 1, pp: 169 },

  { i: 175 - 1, pp: 191 },
  { i: 187 - 1, pp: 205 },
];

const nextYear = {
  freizeit: [{ pp: 95 }, { pp: 130 }, { pp: 108 }, { pp: 105 }, { pp: 106 }],
  region: [{ pp: 167 }, { pp: 87 }, { pp: 169 }, { pp: 187 }, { pp: 202 }],

  gastro1: [
    { pp: 13 },
    { pp: 35 },
    { pp: 19 },
    { pp: 31 },

    { pp: 33 },
    { pp: 69 },
  ],
  gastro2: [{ pp: 17 }, { pp: 41 }, { pp: 81 }, { pp: 15 }, { pp: 13 }],
};

export function ThisYearOffers() {
  return (
    <Offers
      title="Angebote 2025"
      freizeit={freizeit}
      region={region}
      gastro1={gastro1}
      gastro2={gastro2}
      imageFolder="preview2025_ads"
      path="/angebote"
    />
  );
}

export function NextYearOffers() {
  return (
    <Offers
      title="Angebote 2025"
      freizeit={nextYear.freizeit}
      region={nextYear.region}
      gastro1={nextYear.gastro1}
      gastro2={nextYear.gastro2}
      imageFolder="preview2025_ads"
      path="/angebote"
    />
  );
}

export default ThisYearOffers;

class Offers extends Component {
  render() {
    return (
      <div className="offers-container">
        <h1 className="offers-header">{this.props.title}</h1>
        {/* <div className="offers-subheader">
          über <Link to={PATH}>450 Gutscheine</Link> von mehr als{" "}
          <Link to={PATH}>250 Partnerunternehmen</Link>
        </div> */}

        <div className="row1">
          <BigOfferCategory
            categoryIndex={2}
            title="Gastronomie BS"
            images1={this.props.gastro1}
            images2={this.props.gastro2}
            path={this.props.path}
            imageFolder={this.props.imageFolder}
          />
        </div>

        <div className="row" style={{ marginTop: 20 }}>
          <OfferCategory
            categoryIndex={0}
            title="Freizeit, Handel & Dienstleistung BS"
            images={this.props.freizeit}
            path={this.props.path}
            imageFolder={this.props.imageFolder}
          />

          <OfferCategory
            categoryIndex={1}
            title="Angebote aus der Region"
            images={this.props.region}
            path={this.props.path}
            imageFolder={this.props.imageFolder}
          />
        </div>

        {/* <div className="row1" style={{ marginTop: 20 }}>
          <BigOfferCategory
            categoryIndex={2}
            title="Gastronomie BS"
            images1={this.props.gastro1}
            images2={this.props.gastro2}
            path={this.props.path}
            imageFolder={this.props.imageFolder}
          />
        </div> */}
      </div>
    );
  }
}

class OfferCategory extends Component {
  render() {
    const images = this.props.images || ["p39", "p43", "p17", "p33", "p24"];
    const clName =
      this.props.categoryIndex % 2 === 0
        ? "offers-category-block_content_left"
        : "offers-category-block_content_right";

    //console.log("!!!!",this.props.router.push);
    return (
      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 offers-category-block">
        <div className={clName}>
          <h4 style={{ textAlign: "center" }}>
            <Link to={this.props.path}>{this.props.title}</Link>
          </h4>
          <CategoryImages
            images={images}
            imageFolder={this.props.imageFolder}
            path={this.props.path}
          />
        </div>
      </div>
    );
  }
}

const CategoryImages = ({ images, imageFolder, path, showOthers = true }) => (
  <div className="row">
    <div className="col-lg-3 col-xs-4 col-md-4 col-sm-6">
      <ImageLink image={images[0]} imageFolder={imageFolder} path={path} />
    </div>
    <div className="col-lg-3 col-xs-4 col-md-4 col-sm-6">
      <ImageLink image={images[1]} imageFolder={imageFolder} path={path} />
    </div>

    <div className="col-lg-3 col-xs-4 col-md-4 col-sm-6">
      <ImageLink image={images[2]} imageFolder={imageFolder} path={path} />
    </div>

    <div className="col-lg-3 col-xs-4 col-md-4 col-sm-6 hidden-lg hidden-sm ">
      <ImageLink image={images[3]} imageFolder={imageFolder} path={path} />
    </div>

    <div className="col-lg-3 col-xs-4 col-md-4 col-sm-6 hidden-lg hidden-sm ">
      <ImageLink image={images[4]} imageFolder={imageFolder} path={path} />
    </div>

    {!showOthers && (
      <div className="col-lg-3 col-xs-4 col-md-4 col-sm-6  ">
        <ImageLink image={images[5]} imageFolder={imageFolder} path={path} />
      </div>
    )}

    {showOthers && (
      <div
        className="col-lg-3 col-xs-4 col-md-4 col-sm-6 "
        style={{ backgroundColor: "#fefefe" }}
      >
        <div className="offers-others">
          <Link
            to={path}
            className="thumbnail"
            style={{ border: "none", verticalAlign: "middle" }}
          >
            <span
              style={{
                position: "absolute",
                margin: "20px 12px",
                marginRight: 20,
              }}
            >
              <span className="hidden-xs">Weitere Angebote</span>
              <span className="hidden-lg hidden-md hidden-sm">Mehr...</span>
            </span>

            <img alt="angebote" src={emptyImage} />
          </Link>
        </div>
      </div>
    )}
  </div>
);

class BigOfferCategory extends Component {
  render() {
    console.log("!!!!", this.props);
    return (
      <div className="offers-category-block">
        <h4 style={{ textAlign: "center" }}>
          <Link to={this.props.path}>{this.props.title}</Link>
        </h4>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div className={"offers-category-block_content_left"}>
              <CategoryImages
                images={this.props.images1}
                showOthers={false}
                imageFolder={this.props.imageFolder}
                path={this.props.path}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
            <div className={"offers-category-block_content_right"}>
              <CategoryImages
                images={this.props.images2}
                imageFolder={this.props.imageFolder}
                path={this.props.path}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ImageLink = ({ image, imageFolder, path }) => (
  <Link
    to={{
      pathname: path,
      state: {
        //  li: image.i,
        p: image.pp,
      },
    }}
    className="thumbnail"
    style={{ border: "none" }}
  >
    <Image
      alt="angebote"
      year={imageFolder}
      image={`flips-2025-${image.pp}.jpg`}
    />
  </Link>
);
